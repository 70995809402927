import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import {
  getCurrentProfile,
  getProfileHub,
} from '@aries/aptos-defi/lending/data';
import { withSendTxNotify } from '@aries/aptos-defi/utils';

export const enterEmode = withSendTxNotify(async (emodeId: string) => {
  const res = await getAriesSDK()
    .controller.enterEmode({
      profile_name: getCurrentProfile().name,
      emode_id: emodeId,
    })
    .execute();

  getProfileHub()?.refreshCurrentProfile();

  return res;
});

export const exitEmode = withSendTxNotify(async () => {
  const res = await getAriesSDK()
    .controller.exitEmode({
      profile_name: getCurrentProfile().name,
    })
    .execute();

  getProfileHub()?.refreshCurrentProfile();

  return res;
});
