import { AptosProvider, createAptosSDK } from '@aries-markets/create-sdk';
import { getProviderHub } from '@aries/aptos-defi/common';
import { memoize } from 'lodash';
import { UsdtDefine, UsdtIdl } from './idl';

const getUSDTFaucetSDKMemo = memoize(
  (provider: AptosProvider, program: string) =>
    createAptosSDK<UsdtDefine>({
      provider,
      program,
      idlJSON: UsdtIdl,
    }),
);

export const getUSDTFaucetSDK = () => ({
  ...getUSDTFaucetSDKMemo(
    getProviderHub()?.provider!,
    '0xeef200f2a06957a1548685c0feec9f1a04db27598fadab7f6daf30428fd064d3',
  ),
});
