import { useBalanceHub } from '@aries/aptos-defi/common';
import {
  useProfileHub,
  useReserves,
} from '@aries/aptos-defi/lending/data';
import { Asset, Big } from '@aries/defi-toolkit/types';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { MAINNET_USDT_WCOIN } from '../config';

const commonCoin = [
  '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T', // USDC
  MAINNET_USDT_WCOIN, // USDT
  '0x1::aptos_coin::AptosCoin', // APT
  '0xae478ff7d83ed072dbc5e264250e67ef58f57c99d89b447efd8a0a2e8b2be76e::coin::T', // WBTC
  '0xdd89c0e695df0692205912fb69fc290418bed0dbe6e4573d744a6d5e6bab6c13::coin::T', // SOL
];

export const usePairSelectOptions = (
  totalAssetList: Asset[],
  enableBorrow: boolean,
): {
  fromList: Asset[];
  toList: Asset[];
} => {
  const { balanceMap } = useBalanceHub();
  const { currentProfile } = useProfileHub();
  const { reserveMap } = useReserves();

  const [fromList, toList] = useMemo(() => {
    const list = (
      enableBorrow
        ? totalAssetList.filter(a => !!reserveMap[a.id])
        : totalAssetList
    ).map(v => ({ ...v, isCommon: commonCoin.includes(v.id) }));

    return [list, list];
  }, [totalAssetList, reserveMap, enableBorrow]);

  const fromListWithBalance = useMemo(() => {
    return sortBy(
      fromList.map(item => {
        const balance = balanceMap[item.id];
        const deposit = currentProfile?.getDepositedLamports(item.id);

        return {
          ...item,
          wallet: item.lamportsToBalance(balance.amount ?? Big(0)),
          deposit: deposit ? item.lamportsToBalance(deposit) : undefined,
        };
      }),
      a => -(a.wallet.amountNum ?? 0),
    );
  }, [fromList, balanceMap, currentProfile]);

  const toListWithBalance = useMemo(() => {
    return sortBy(
      toList.map(item => {
        const balance = balanceMap[item.id];
        const deposit = currentProfile?.getDepositedLamports(item.id);

        return {
          ...item,
          wallet: item.lamportsToBalance(balance?.amount ?? Big(0)),
          deposit: deposit ? item.lamportsToBalance(deposit) : undefined,
        };
      }),
      a => -(a.wallet.amountNum ?? 0),
    );
  }, [toList, balanceMap, currentProfile]);

  return {
    fromList: fromListWithBalance,
    toList: toListWithBalance,
  };
};
