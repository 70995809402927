export const WALLET_POSITION_MAP: Record<string, number | undefined> = {
  'Continue with Google': 0,
  Petra: 1,
  'OKX Wallet': 2,
  'Bitget Wallet': 3,
  'Bybit Wallet': 4,
  'Mizu Wallet': 5,
  'Pontem Wallet': 6,
  Martian: 7,
  Rise: 8,
  Blocto: 9,
  TrustWallet: 10,
  Fewcha: 11,
  MSafe: 12,
};
