import Big from 'big.js';

export type UsdtDefine = {
  usdt: [
    {},
    {
      mint: {
        args: { amount: Big; to: string };
        typeArgs: never;
      };
    },
  ];
};

export const UsdtIdl = {
  modules: [
    {
      name: 'usdt',
      displayName: 'usdt',
      methods: [
        {
          name: 'mint',
          displayName: 'mint',
          args: [
            {
              name: 'to',
              jsType: 'string',
              moveType: 'address',
            },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [],
        },
      ],
      structs: [],
    },
  ],
};
