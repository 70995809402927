import { Network } from '@aptos-labs/ts-sdk';
import {
  ARIES_PROGRAM_ADDRESS,
  ARIES_TEST_PROGRAM_ADDRESS,
  ECONIA_PROGRAM_ADDRESS,
  ECONIA_TEST_PROGRAM_ADDRESS,
} from '@aries/aptos-defi/config';
import { createGlobalStore } from '@aries/shared/deps';
import { eventEmitter, useLocalStorageRef } from '@aries/shared/utils';
import { FaucetClient } from 'aptos';
import { useEffect, useRef, useState } from 'react';
import { createProvider } from './impl';

export { MAX_GAS_PER_TX } from './impl';
export { Network };

const list = [
  {
    name: 'Mainnet - NodeReal',
    desc: 'Aptos Mainnet.',
    nodeUrl:
      'https://aptos-mainnet.nodereal.io/v1/dbe3294d24374cad9d0886ca12d0aeb7',
    faucetUrl: '-',
    network: Network.MAINNET,
  },
  {
    name: 'Mainnet - Blast Api',
    desc: 'Aptos Mainnet.',
    nodeUrl:
      'https://aptos-mainnet.blastapi.io/c582035a-0a6f-4ea5-bac0-00dd4937c1ac',
    faucetUrl: '-',
    network: Network.MAINNET,
  },
  {
    name: 'Mainnet - Official Node',
    desc: 'Aptos Mainnet.',
    nodeUrl: 'https://fullnode.mainnet.aptoslabs.com/v1',
    faucetUrl: '-',
    network: Network.MAINNET,
  },
  {
    name: 'Testnet - NodeReal',
    desc: 'Aptos Testnet.',
    nodeUrl:
      'https://aptos-testnet.nodereal.io/v1/98ef20c983004209b66b8ed5eb6bb8a4',
    // nodeUrl: 'https://fullnode.testnet.aptoslabs.com/v1',
    faucetUrl: '-',
    network: Network.TESTNET,
  },
];

const NETWORK_CHANGE_KEY = 'AptosProviderChange';
const NETWORK_NAME_KEY = 'last_network_name_1';

export const onNetworkChange = (
  callback: (
    network: typeof list[number],
    provider: ReturnType<typeof useProviderHub>['provider'],
  ) => void,
) => {
  eventEmitter.addListener(NETWORK_CHANGE_KEY, (network, provider) => {
    callback(network, provider);
  });
};

export const [useProviderHub, getProviderHub] = createGlobalStore(() => {
  const [networkNameRef, setLastNetworkName] =
    useLocalStorageRef<string>(NETWORK_NAME_KEY);

  const getProvider = (name?: string) => {
    const meta = list.find(v => v.name === name);
    if (!meta) {
      return null;
    }

    const provider = createProvider({
      nodeUrl: meta.nodeUrl,
      network: meta.network,
    });

    const faucetClient = new FaucetClient(meta.nodeUrl, meta.faucetUrl);

    return { faucetClient, provider, meta };
  };

  const [{ provider, faucetClient, meta: currentNetwork }, setProvider] =
    // TODO
    useState(
      () =>
        getProvider(networkNameRef.current) ?? getProvider(list[0].name)!,
    );

  // To make we can get the newest provider immediately
  const providerRef = useRef(provider);
  providerRef.current = provider;

  const changeNetwork = (name: string) => {
    const newProvider = getProvider(name);
    if (newProvider) {
      setProvider(newProvider);
      setLastNetworkName(name);
    }
  };

  const env = {
    isDevnet: currentNetwork.network === Network.DEVNET,
    isTestnet: currentNetwork.network === Network.TESTNET,
    isMainnet: currentNetwork.network === Network.MAINNET,
  };

  const ARIES_PROGRAM = env.isTestnet
    ? ARIES_TEST_PROGRAM_ADDRESS
    : ARIES_PROGRAM_ADDRESS;

  const ECONIA_PROGRAM = env.isTestnet
    ? ECONIA_TEST_PROGRAM_ADDRESS
    : ECONIA_PROGRAM_ADDRESS;

  useEffect(() => {
    eventEmitter.emit(NETWORK_CHANGE_KEY, currentNetwork, provider);
  }, [currentNetwork, provider]);

  return {
    provider: providerRef.current,
    faucetClient,
    currentNetwork,
    list,
    changeNetwork,
    ARIES_PROGRAM,
    ECONIA_PROGRAM,
    env: {
      isDevnet: currentNetwork.network === Network.DEVNET,
      isTestnet: currentNetwork.network === Network.TESTNET,
      isMainnet: currentNetwork.network === Network.MAINNET,
    },
  };
});

export const getAriesProgram = () => getProviderHub()?.ARIES_PROGRAM!;
