import { createGlobalStore } from '@aries/shared/deps';
import Big from 'big.js';
import { keyBy, mapValues } from 'lodash';
import useSWR from 'swr';
import { getV2AssetTypeToV1Map } from '../config';
import { useWallet } from '../wallet';
import {
  getProviderHub,
  MAX_GAS_PER_TX,
  useProviderHub,
} from './aptos-provider';
import { APTOS_COIN_ADDRESS } from './token-info';

const emptyProxy = (rawMap: any): Record<string, { amount: Big }> =>
  new Proxy(rawMap, {
    get: (map, prop: string) => map[prop] ?? { amount: Big(0) },
  });

export const useBalanceHubByAddress = (
  walletAddress: string | undefined,
) => {
  const {
    currentNetwork,
    provider: { aptos },
    env,
  } = useProviderHub();

  const v2AssetTypeToV1Map = getV2AssetTypeToV1Map(env);
  const { data: balanceMap = emptyProxy({}), mutate } = useSWR(
    [
      'BalanceHub',
      walletAddress,
      currentNetwork.network,
      v2AssetTypeToV1Map,
    ],
    async () => {
      // Will not return empty value.
      if (walletAddress) {
        const res = await aptos.getCurrentFungibleAssetBalances({
          options: {
            where: {
              owner_address: {
                _eq: walletAddress,
              },
            },
          },
        });

        const standardV1Tokens = res
          .filter(_balance => _balance.token_standard === 'v1')
          .map(_balance => ({
            ..._balance,
            amount: Big(`${_balance.amount}`),
          }));

        const tokensByAssetType = keyBy(
          standardV1Tokens.filter(_token => !!_token.asset_type),
          i => i.asset_type || '',
        );
        const balanceMapInner = emptyProxy(
          mapValues(tokensByAssetType, v => ({
            amount: Big(`${v.amount}`),
          })) as Record<string, { amount: Big }>,
        );

        const standardV2Tokens = res
          .filter(_balance => _balance.token_standard === 'v2')
          .map(_balance => ({
            ..._balance,
            amount: Big(`${_balance.amount}`),
          }));

        standardV2Tokens.forEach(_token => {
          const v2TokenType = _token.asset_type ?? '';
          const v1AssetType = v2AssetTypeToV1Map[v2TokenType];
          if (v1AssetType) {
            balanceMapInner[v1AssetType] = {
              amount: balanceMapInner[v1AssetType].amount.add(
                _token.amount,
              ),
            };
          }

          if (v2TokenType) {
            balanceMapInner[v2TokenType] = {
              amount: Big(_token.amount),
            };
          }
        });
        return balanceMapInner;
      }

      return emptyProxy({});
    },
    {
      refreshInterval: 60 * 1000,
    },
  );

  return { balanceMap, refresh: () => mutate(v => v, true) };
};

export const [useBalanceHub, getBalanceHub] = createGlobalStore(() => {
  const { walletAddress } = useWallet();
  return useBalanceHubByAddress(walletAddress);
});

export const hasEnoughGasOrThrow = () => {
  const aptosBalance =
    getBalanceHub()!.balanceMap[APTOS_COIN_ADDRESS]?.amount ?? Big(0);

  if (aptosBalance.lte(MAX_GAS_PER_TX)) {
    throw `You need to have ${
      MAX_GAS_PER_TX / 10 ** 8
    } APTOS at least to send transaction. ${
      getProviderHub()?.env.isTestnet ? 'Go to Faucet to get Aptos!' : ''
    } ` as any;
  }
};
