import Big from 'big.js';
import { ControllerConfig } from './controller_config';
import { Trade } from './econia_adaptor';
import { RecognizedMarkets } from './econia_stub';
import { EModeCategories } from './emode_category';
import { OracleIndex } from './oracle';
import { Profile, Profiles } from './profile';
import { ReserveCoinContainer, Reserves } from './reserve';
import { RewardContainer } from './reward_container';

export type AriesDefine = {
  controller: [
    {},
    {
      init: {
        args: { admin_addr: string };
        typeArgs: never;
      };
      initRewardContainer: {
        args: never;
        typeArgs: { Coin0: string };
      };
      addReserve: {
        args: never;
        typeArgs: { Coin0: string };
      };
      registerUser: {
        args: { default_profile_name: string };
        typeArgs: never;
      };
      registerUserWithReferrer: {
        args: { default_profile_name: string; referrer_addr: string };
        typeArgs: never;
      };
      addSubaccount: {
        args: { profile_name: string };
        typeArgs: never;
      };
      mint: {
        args: { amount: Big };
        typeArgs: { Coin0: string };
      };
      redeem: {
        args: { amount: Big };
        typeArgs: { Coin0: string };
      };
      addCollateral: {
        args: { profile_name: string; amount: Big };
        typeArgs: { Coin0: string };
      };
      removeCollateral: {
        args: { profile_name: string; amount: Big };
        typeArgs: { Coin0: string };
      };
      deposit: {
        args: { profile_name: string; amount: Big; repay_only: boolean };
        typeArgs: { Coin0: string };
      };
      depositFa: {
        args: { profile_name: string; amount: Big };
        typeArgs: { WCoin: string };
      };
      withdraw: {
        args: { profile_name: string; amount: Big; allow_borrow: boolean };
        typeArgs: { Coin0: string };
      };
      withdrawFa: {
        args: { profile_name: string; amount: Big; allow_borrow: boolean };
        typeArgs: { WCoin: string };
      };
      liquidate: {
        args: {
          liquidatee_addr: string;
          liquidatee_profile_name: string;
          amount: Big;
        };
        typeArgs: { RepayCoin: string; WithdrawCoin: string };
      };
      liquidateAndRedeem: {
        args: {
          liquidatee_addr: string;
          liquidatee_profile_name: string;
          amount: Big;
        };
        typeArgs: { RepayCoin: string; WithdrawCoin: string };
      };
      swap: {
        args: {
          profile_name: string;
          market_id: Big;
          style: boolean;
          host: string;
          amount: Big;
          minimum_out: Big;
          allow_borrow: boolean;
        };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
      placeLimitOrder: {
        args: {
          profile_name: string;
          side_bid: boolean;
          amount: Big;
          price_millionth: Big;
          allow_borrow: boolean;
        };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
      settleTradingFundsInMarket: {
        args: { profile_name: string };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
      cancelLimitOrder: {
        args: {
          profile_name: string;
          side_bid: boolean;
          order_id: Big;
        };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
      cancelAllLimitOrders: {
        args: { profile_name: string };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
      registerOrUpdatePrivilegedReferrer: {
        args: { claimant_addr: string; fee_sharing_percentage: number };
        typeArgs: never;
      };
      addReward: {
        args: { amount: Big };
        typeArgs: {
          ReserveCoin: string;
          FarmingType: string;
          RewardCoin: string;
        };
      };
      removeReward: {
        args: { amount: Big };
        typeArgs: {
          ReserveCoin: string;
          FarmingType: string;
          RewardCoin: string;
        };
      };
      claimReward: {
        args: { profile_name: string };
        typeArgs: {
          ReserveCoin: string;
          FarmingType: string;
          RewardCoin: string;
        };
      };
      updateRewardRate: {
        args: { reward_per_day: unknown };
        typeArgs: {
          ReserveCoin: string;
          FarmingType: string;
          RewardCoin: string;
        };
      };
      updateReserveConfig: {
        args: {
          loan_to_value: number;
          liquidation_threshold: number;
          liquidation_bonus_bips: Big;
          liquidation_fee_hundredth_bips: Big;
          borrow_factor: number;
          reserve_ratio: number;
          borrow_fee_hundredth_bips: Big;
          withdraw_fee_hundredth_bips: Big;
          deposit_limit: Big;
          borrow_limit: Big;
          allow_collateral: boolean;
          allow_redeem: boolean;
          flash_loan_fee_hundredth_bips: Big;
        };
        typeArgs: { Coin0: string };
      };
      updateInterestRateConfig: {
        args: {
          min_borrow_rate: Big;
          optimal_borrow_rate: Big;
          max_borrow_rate: Big;
          optimal_utilization: Big;
        };
        typeArgs: { Coin0: string };
      };
      setEmodeCategory: {
        args: {
          id: string;
          label: string;
          loan_to_value: number;
          liquidation_threshold: number;
          liquidation_bonus_bips: Big;
          borrow_factor: number;
        };
        typeArgs: { OracleType: string };
      };
      reserveEnterEmode: {
        args: { emode_id: string };
        typeArgs: { ReserveType: string };
      };
      reserveExitEmode: {
        args: never;
        typeArgs: { ReserveType: string };
      };
      enterEmode: {
        args: { profile_name: string; emode_id: string };
        typeArgs: never;
      };
      exitEmode: {
        args: { profile_name: string };
        typeArgs: never;
      };
      hippoSwap: {
        args: {
          profile_name: string;
          allow_borrow: boolean;
          amount: Big;
          minimum_out: Big;
          num_steps: number;
          first_dex_type: number;
          first_pool_type: Big;
          first_is_x_to_y: boolean;
          second_dex_type: number;
          second_pool_type: Big;
          second_is_x_to_y: boolean;
          third_dex_type: number;
          third_pool_type: Big;
          third_is_x_to_y: boolean;
        };
        typeArgs: {
          InCoin: string;
          Y: string;
          Z: string;
          OutCoin: string;
          E1: string;
          E2: string;
          E3: string;
        };
      };
    },
  ];
  controller_config: [
    { ControllerConfig: { type: ControllerConfig } },
    {},
  ];
  emode_category: [
    { EModeCategories: { type: EModeCategories } },
    {
      profileEmode: {
        args: { profile_account: string };
        typeArgs: never;
      };
      reserveEmode: {
        args: never;
        typeArgs: { ReserveType: string };
      };
      reserveInEmode: {
        args: { emode_id: string };
        typeArgs: { ReserveType: string };
      };
      emodeConfig: {
        args: { emode_id: string };
        typeArgs: never;
      };
    },
  ];
  econia_adaptor: [{ Trade: { type: Trade } }, {}];
  econia_stub: [
    { RecognizedMarkets: { type: RecognizedMarkets } },
    {
      init: {
        args: never;
        typeArgs: never;
      };
      addMarket: {
        args: { lot_size: Big; tick_size: Big };
        typeArgs: { BaseCoin: string; QuoteCoin: string };
      };
    },
  ];
  profile: [
    { Profiles: { type: Profiles }; Profile: { type: Profile } },
    {
      profileFarmCoin: {
        args: { user_address: string; profile_name: string };
        typeArgs: {
          ReserveType: string;
          FarmingType: string;
          RewardCoin: string;
        };
      };
    },
  ];
  reserve: [
    {
      Reserves: { type: Reserves };
      ReserveCoinContainer: {
        type: ReserveCoinContainer<any>;
        typeArgs: 'Coin0';
      };
    },
    {},
  ];
  reward_container: [
    {
      RewardContainer: {
        type: RewardContainer<any>;
        typeArgs: 'CoinType';
      };
    },
    {},
  ];
  oracle: [{ OracleIndex: { type: OracleIndex } }, {}];
};

export const AriesIdl = {
  modules: [
    {
      name: 'controller',
      displayName: 'controller',
      methods: [
        {
          name: 'init',
          displayName: 'init',
          args: [
            { name: 'admin_addr', jsType: 'string', moveType: 'address' },
          ],
          typeArgs: [],
        },
        {
          name: 'init_reward_container',
          displayName: 'initRewardContainer',
          args: [],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'add_reserve',
          displayName: 'addReserve',
          args: [],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'register_user',
          displayName: 'registerUser',
          args: [
            {
              name: 'default_profile_name',
              jsType: 'string',
              moveType: 'vector',
            },
          ],
          typeArgs: [],
        },
        {
          name: 'register_user_with_referrer',
          displayName: 'registerUserWithReferrer',
          args: [
            {
              name: 'default_profile_name',
              jsType: 'string',
              moveType: 'vector',
            },
            {
              name: 'referrer_addr',
              jsType: 'string',
              moveType: 'address',
            },
          ],
          typeArgs: [],
        },
        {
          name: 'add_subaccount',
          displayName: 'addSubaccount',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
          ],
          typeArgs: [],
        },
        {
          name: 'mint',
          displayName: 'mint',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'redeem',
          displayName: 'redeem',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'add_collateral',
          displayName: 'addCollateral',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'remove_collateral',
          displayName: 'removeCollateral',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'deposit',
          displayName: 'deposit',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'repay_only', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'deposit_fa',
          displayName: 'depositFa',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'WCoin' }],
        },
        {
          name: 'withdraw',
          displayName: 'withdraw',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'withdraw_fa',
          displayName: 'withdrawFa',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [{ name: 'WCoin' }],
        },
        {
          name: 'liquidate_and_redeem',
          displayName: 'liquidateAndRedeem',
          args: [
            {
              name: 'liquidatee_addr',
              jsType: 'string',
              moveType: 'address',
            },
            {
              name: 'liquidatee_profile_name',
              jsType: 'string',
              moveType: 'vector',
            },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'RepayCoin' }, { name: 'WithdrawCoin' }],
        },
        {
          name: 'liquidate',
          displayName: 'liquidate',
          args: [
            {
              name: 'liquidatee_addr',
              jsType: 'string',
              moveType: 'address',
            },
            {
              name: 'liquidatee_profile_name',
              jsType: 'string',
              moveType: 'vector',
            },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'RepayCoin' }, { name: 'WithdrawCoin' }],
        },
        {
          name: 'swap',
          displayName: 'swap',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'market_id', jsType: 'Big', moveType: 'u64' },
            { name: 'style', jsType: 'boolean', moveType: 'bool' },
            { name: 'host', jsType: 'string', moveType: 'address' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'minimum_out', jsType: 'Big', moveType: 'u64' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
        {
          name: 'place_limit_order',
          displayName: 'placeLimitOrder',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'side_bid', jsType: 'boolean', moveType: 'bool' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'price_millionth', jsType: 'Big', moveType: 'u64' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
        {
          name: 'hippo_swap',
          displayName: 'hippoSwap',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'allow_borrow', jsType: 'boolean', moveType: 'bool' },
            { name: 'amount', jsType: 'Big', moveType: 'u64' },
            { name: 'minimum_out', jsType: 'Big', moveType: 'u64' },
            { name: 'num_steps', jsType: 'number', moveType: 'u8' },
            { name: 'first_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'first_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'first_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
            { name: 'second_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'second_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'second_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
            { name: 'third_dex_type', jsType: 'number', moveType: 'u8' },
            { name: 'third_pool_type', jsType: 'Big', moveType: 'u64' },
            {
              name: 'third_is_x_to_y',
              jsType: 'boolean',
              moveType: 'bool',
            },
          ],
          typeArgs: [
            { name: 'InCoin' },
            { name: 'Y' },
            { name: 'Z' },
            { name: 'OutCoin' },
            { name: 'E1' },
            { name: 'E2' },
            { name: 'E3' },
          ],
        },
        {
          name: 'settle_trading_funds_in_market',
          displayName: 'settleTradingFundsInMarket',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
        {
          name: 'cancel_limit_order',
          displayName: 'cancelLimitOrder',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
            { name: 'side_bid', jsType: 'boolean', moveType: 'bool' },
            { name: 'order_id', jsType: 'unknown', moveType: 'u128' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
        {
          name: 'cancel_all_limit_orders',
          displayName: 'cancelAllLimitOrders',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
        {
          name: 'register_or_update_privileged_referrer',
          displayName: 'registerOrUpdatePrivilegedReferrer',
          args: [
            {
              name: 'claimant_addr',
              jsType: 'string',
              moveType: 'address',
            },
            {
              name: 'fee_sharing_percentage',
              jsType: 'number',
              moveType: 'u8',
            },
          ],
          typeArgs: [],
        },
        {
          name: 'add_reward',
          displayName: 'addReward',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [
            { name: 'ReserveCoin' },
            { name: 'FarmingType' },
            { name: 'RewardCoin' },
          ],
        },
        {
          name: 'remove_reward',
          displayName: 'removeReward',
          args: [{ name: 'amount', jsType: 'Big', moveType: 'u64' }],
          typeArgs: [
            { name: 'ReserveCoin' },
            { name: 'FarmingType' },
            { name: 'RewardCoin' },
          ],
        },
        {
          name: 'claim_reward',
          displayName: 'claimReward',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'vector' },
          ],
          typeArgs: [
            { name: 'ReserveCoin' },
            { name: 'FarmingType' },
            { name: 'RewardCoin' },
          ],
        },
        {
          name: 'update_reward_rate',
          displayName: 'updateRewardRate',
          args: [
            {
              name: 'reward_per_day',
              jsType: 'unknown',
              moveType: 'u128',
            },
          ],
          typeArgs: [
            { name: 'ReserveCoin' },
            { name: 'FarmingType' },
            { name: 'RewardCoin' },
          ],
        },
        {
          name: 'update_reserve_config',
          displayName: 'updateReserveConfig',
          args: [
            { name: 'loan_to_value', jsType: 'number', moveType: 'u8' },
            {
              name: 'liquidation_threshold',
              jsType: 'number',
              moveType: 'u8',
            },
            {
              name: 'liquidation_bonus_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
            {
              name: 'liquidation_fee_hundredth_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
            { name: 'borrow_factor', jsType: 'number', moveType: 'u8' },
            { name: 'reserve_ratio', jsType: 'number', moveType: 'u8' },
            {
              name: 'borrow_fee_hundredth_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
            {
              name: 'withdraw_fee_hundredth_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
            { name: 'deposit_limit', jsType: 'Big', moveType: 'u64' },
            { name: 'borrow_limit', jsType: 'Big', moveType: 'u64' },
            {
              name: 'allow_collateral',
              jsType: 'boolean',
              moveType: 'bool',
            },
            { name: 'allow_redeem', jsType: 'boolean', moveType: 'bool' },
            {
              name: 'flash_loan_fee_hundredth_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'update_interest_rate_config',
          displayName: 'updateInterestRateConfig',
          args: [
            { name: 'min_borrow_rate', jsType: 'Big', moveType: 'u64' },
            {
              name: 'optimal_borrow_rate',
              jsType: 'Big',
              moveType: 'u64',
            },
            { name: 'max_borrow_rate', jsType: 'Big', moveType: 'u64' },
            {
              name: 'optimal_utilization',
              jsType: 'Big',
              moveType: 'u64',
            },
          ],
          typeArgs: [{ name: 'Coin0' }],
        },
        {
          name: 'set_emode_category',
          displayName: 'setEmodeCategory',
          args: [
            { name: 'id', jsType: 'string', moveType: 'String' },
            { name: 'label', jsType: 'string', moveType: 'String' },
            { name: 'loan_to_value', jsType: 'number', moveType: 'u8' },
            {
              name: 'liquidation_threshold',
              jsType: 'number',
              moveType: 'u8',
            },
            {
              name: 'liquidation_bonus_bips',
              jsType: 'Big',
              moveType: 'u64',
            },
            { name: 'borrow_factor', jsType: 'number', moveType: 'u8' },
          ],
          typeArgs: [{ name: 'OracleType' }],
        },
        {
          name: 'reserve_enter_emode',
          displayName: 'reserveEnterEmode',
          args: [
            { name: 'emode_id', jsType: 'string', moveType: 'String' },
          ],
          typeArgs: [{ name: 'ReserveType' }],
        },
        {
          name: 'reserve_exit_emode',
          displayName: 'reserveExitEmode',
          args: [],
          typeArgs: [{ name: 'ReserveType' }],
        },
        {
          name: 'enter_emode',
          displayName: 'enterEmode',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'String' },
            { name: 'emode_id', jsType: 'string', moveType: 'String' },
          ],
          typeArgs: [],
        },
        {
          name: 'exit_emode',
          displayName: 'exitEmode',
          args: [
            { name: 'profile_name', jsType: 'string', moveType: 'String' },
          ],
          typeArgs: [],
        },
      ],
      structs: [],
    },
    {
      name: 'controller_config',
      displayName: 'controller_config',
      methods: [],
      structs: [
        {
          name: 'ControllerConfig',
          displayName: 'ControllerConfig',
          typeArgs: [],
          moveSchema: {
            admin: 'address',
            referral: {
              structName: 'ReferralDetails',
              module: 'referral',
              schema: {
                configs: {
                  structName: 'TableWithLength',
                  typeArgs: [
                    'address',
                    {
                      structName: 'ReferralConfig',
                      module: 'referral',
                      schema: { fee_sharing_percentage: 'u8' },
                    },
                  ],
                },
              },
            },
          },
        },
      ],
    },
    {
      name: 'econia_adaptor',
      displayName: 'econia_adaptor',
      methods: [],
      structs: [
        {
          name: 'Trade',
          displayName: 'Trade',
          typeArgs: [],
          moveSchema: {
            trade_cap: 'CustodianCapability',
            trade_details: {
              structName: { module: 'map', member: 'Map' },
              typeArgs: [
                'Pair',
                {
                  structName: 'MarketTradeDetails',
                  module: 'econia_adaptor',
                  schema: {},
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'econia_stub',
      displayName: 'econia_stub',
      methods: [
        {
          name: 'init',
          displayName: 'init',
          args: [],
          typeArgs: [],
        },
        {
          name: 'add_market',
          displayName: 'addMarket',
          args: [
            { name: 'lot_size', jsType: 'Big', moveType: 'u64' },
            { name: 'tick_size', jsType: 'Big', moveType: 'u64' },
          ],
          typeArgs: [{ name: 'BaseCoin' }, { name: 'QuoteCoin' }],
        },
      ],
      structs: [
        {
          name: 'RecognizedMarkets',
          displayName: 'RecognizedMarkets',
          typeArgs: [],
          moveSchema: {
            map: {
              structName: 'Map',
              typeArgs: [
                {
                  structName: 'AssetPair',
                  module: 'econia_stub',
                  schema: {
                    base_type_info: 'TypeInfo',
                    quote_type_info: 'TypeInfo',
                  },
                },
                {
                  structName: 'MarketMetadata',
                  module: 'econia_stub',
                  schema: {
                    market_id: 'u64',
                    host: 'address',
                    lot_size: 'u64',
                    tick_size: 'u64',
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'emode_category',
      displayName: 'emode_category',
      methods: [
        {
          name: 'profile_emode',
          displayName: 'profileEmode',
          args: [
            {
              name: 'profile_account',
              jsType: 'string',
              moveType: 'address',
            },
          ],
          typeArgs: [],
        },
        {
          name: 'reserve_emode',
          displayName: 'reserveEmode',
          args: [],
          typeArgs: [{ name: 'ReserveType' }],
        },
        {
          name: 'reserve_in_emode',
          displayName: 'reserveInEmode',
          args: [
            { name: 'emode_id', jsType: 'string', moveType: 'String' },
          ],
          typeArgs: [{ name: 'ReserveType' }],
        },
        {
          name: 'emode_config',
          displayName: 'emodeConfig',
          args: [
            { name: 'emode_id', jsType: 'string', moveType: 'String' },
          ],
          typeArgs: [],
        },
      ],
      structs: [
        {
          name: 'EModeCategories',
          displayName: 'EModeCategories',
          typeArgs: [],
          moveSchema: {
            admin: 'address',
            categories: {
              structName: 'SimpleMap',
              typeArgs: [
                'String',
                {
                  structName: 'EMode',
                  module: 'emode_category',
                  schema: {
                    label: 'String',
                    oracle_key_type: {
                      structName: 'Option',
                      typeArgs: ['TypeInfo'],
                    },
                    loan_to_value: 'u8',
                    liquidation_threshold: 'u8',
                    liquidation_bonus_bips: 'u64',
                    borrow_factor: 'u8',
                    extension: { structName: 'Option', typeArgs: ['Any'] },
                  },
                },
              ],
            },
            profile_emodes: {
              structName: 'SmartTable',
              typeArgs: ['address', 'String'],
            },
            reserve_emodes: {
              structName: 'TableWithLength',
              typeArgs: ['TypeInfo', 'String'],
            },
            extension: { structName: 'Option', typeArgs: ['Any'] },
          },
        },
      ],
    },
    {
      name: 'profile',
      displayName: 'profile',
      methods: [
        {
          name: 'profile_farm_coin',
          displayName: 'profileFarmCoin',
          args: [
            {
              name: 'user_address',
              jsType: 'string',
              moveType: 'address',
            },
            {
              name: 'profile_name',
              jsType: 'string',
              moveType: 'String',
            },
          ],
          typeArgs: [
            { name: 'ReserveType' },
            { name: 'FarmingType' },
            { name: 'RewardCoin' },
          ],
        },
      ],
      structs: [
        {
          name: 'Profiles',
          displayName: 'Profiles',
          typeArgs: [],
          moveSchema: {
            profile_signers: {
              structName: { module: 'ref_map', member: 'SimpleMap' },
              typeArgs: ['String', 'SignerCapability'],
            },
            referrer: { structName: 'Option', typeArgs: ['address'] },
          },
        },
        {
          name: 'Profile',
          displayName: 'Profile',
          typeArgs: [],
          moveSchema: {
            deposited_reserves: {
              structName: 'IterableTable',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'Deposit',
                  module: 'profile',
                  schema: { collateral_amount: 'u64' },
                },
              ],
            },
            borrowed_reserves: {
              structName: 'IterableTable',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'Loan',
                  module: 'profile',
                  schema: { borrowed_share: 'Decimal' },
                },
              ],
            },
            deposit_farms: {
              structName: 'IterableTable',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'ProfileFarm',
                  module: 'profile_farm',
                  schema: {
                    share: 'u128',
                    rewards: {
                      structName: 'IterableTable',
                      typeArgs: [
                        'TypeInfo',
                        {
                          structName: 'Reward',
                          module: 'profile_farm',
                          schema: {
                            unclaimed_amount: 'Decimal',
                            last_reward_per_share: 'Decimal',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
            borrow_farms: {
              structName: 'IterableTable',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'ProfileFarm',
                  module: 'profile_farm',
                  schema: {
                    share: 'u128',
                    rewards: {
                      structName: 'IterableTable',
                      typeArgs: [
                        'TypeInfo',
                        {
                          structName: 'Reward',
                          module: 'profile_farm',
                          schema: {
                            unclaimed_amount: 'Decimal',
                            last_reward_per_share: 'Decimal',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
    {
      name: 'reserve',
      displayName: 'reserve',
      methods: [],
      structs: [
        {
          name: 'Reserves',
          displayName: 'Reserves',
          typeArgs: [],
          moveSchema: {
            stats: {
              structName: 'Table',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'ReserveDetails',
                  module: 'reserve_details',
                  schema: {
                    total_lp_supply: 'u128',
                    total_cash_available: 'u128',
                    initial_exchange_rate: 'Decimal',
                    reserve_amount: 'Decimal',
                    total_borrowed_share: 'Decimal',
                    total_borrowed: 'Decimal',
                    interest_accrue_timestamp: 'u64',
                    reserve_config: {
                      structName: 'ReserveConfig',
                      module: 'reserve_config',
                      schema: {
                        loan_to_value: 'u8',
                        liquidation_threshold: 'u8',
                        liquidation_bonus_bips: 'u64',
                        liquidation_fee_hundredth_bips: 'u64',
                        borrow_factor: 'u8',
                        reserve_ratio: 'u8',
                        borrow_fee_hundredth_bips: 'u64',
                        withdraw_fee_hundredth_bips: 'u64',
                        deposit_limit: 'u64',
                        borrow_limit: 'u64',
                        allow_collateral: 'bool',
                        allow_redeem: 'bool',
                        flash_loan_fee_hundredth_bips: 'u64',
                      },
                    },
                    interest_rate_config: {
                      structName: 'InterestRateConfig',
                      module: 'interest_rate_config',
                      schema: {
                        min_borrow_rate: 'u64',
                        optimal_borrow_rate: 'u64',
                        max_borrow_rate: 'u64',
                        optimal_utilization: 'u64',
                      },
                    },
                  },
                },
              ],
            },
            farms: {
              structName: 'Table',
              typeArgs: [
                {
                  structName: 'Pair',
                  module: 'pair',
                  schema: {
                    fst: 'TypeInfo',
                    snd: 'TypeInfo',
                  },
                },
                {
                  structName: 'ReserveFarm',
                  module: 'reserve_farm',
                  schema: {
                    timestamp: 'u64',
                    share: 'u128',
                    rewards: {
                      structName: 'IterableTable',
                      typeArgs: [
                        'TypeInfo',
                        {
                          structName: 'Reward',
                          module: 'reserve_farm',
                          schema: {
                            reward_config: {
                              structName: 'RewardConfig',
                              module: 'reserve_farm',
                              schema: { reward_per_day: 'u128' },
                            },
                            remaining_reward: 'u128',
                            reward_per_share: 'Decimal',
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
        {
          name: 'ReserveCoinContainer',
          displayName: 'ReserveCoinContainer',
          typeArgs: [{ name: 'Coin0' }],
          moveSchema: {
            underlying_coin: { structName: 'Coin', typeArgs: ['Coin0'] },
            collateralised_lp_coin: {
              structName: 'Coin',
              typeArgs: [
                { structName: 'LP', module: 'reserve', schema: {} },
              ],
            },
            mint_capability: {
              structName: 'MintCapability',
              typeArgs: [
                { structName: 'LP', module: 'reserve', schema: {} },
              ],
            },
            burn_capability: {
              structName: 'BurnCapability',
              typeArgs: [
                { structName: 'LP', module: 'reserve', schema: {} },
              ],
            },
            freeze_capability: {
              structName: 'FreezeCapability',
              typeArgs: [
                { structName: 'LP', module: 'reserve', schema: {} },
              ],
            },
            fee: { structName: 'Coin', typeArgs: ['Coin0'] },
          },
        },
      ],
    },
    {
      name: 'reward_container',
      displayName: 'reward_container',
      methods: [],
      structs: [
        {
          name: 'RewardContainer',
          displayName: 'RewardContainer',
          typeArgs: [{ name: 'CoinType' }],
          moveSchema: {
            rewards: { structName: 'Map', typeArgs: ['Pair', 'Coin'] },
          },
        },
      ],
    },
    {
      name: 'oracle',
      displayName: 'oracle',
      methods: [],
      structs: [
        {
          name: 'OracleIndex',
          displayName: 'OracleIndex',
          typeArgs: [],
          moveSchema: {
            admin: 'address',
            prices: {
              structName: 'Table',
              typeArgs: [
                'TypeInfo',
                {
                  structName: 'OracleInfo',
                  module: 'oracle',
                  schema: {
                    switchboard: {
                      structName: 'Option',
                      typeArgs: [
                        {
                          structName: 'SwitchboardConfig',
                          module: 'oracle',
                          schema: {
                            sb_addr: 'address',
                            max_age: 'u64',
                            weight: 'u64',
                          },
                        },
                      ],
                    },
                    pyth: {
                      structName: 'Option',
                      typeArgs: [
                        {
                          structName: 'PythConfig',
                          module: 'oracle',
                          schema: {
                            pyth_id: 'PriceIdentifier',
                            max_age: 'u64',
                            weight: 'u64',
                          },
                        },
                      ],
                    },
                    coin_decimals: 'u8',
                    max_deviation: 'Decimal',
                    default_price: {
                      structName: 'Option',
                      typeArgs: ['Decimal'],
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
  ],
};
