import { Aptos, AptosConfig, Network } from '@aptos-labs/ts-sdk';
import { AptosProvider } from '@aries-markets/create-sdk';
import { getWalletCtx } from '@aries/aptos-defi/wallet';
import { AptosClient, HexString, TxnBuilderTypes } from 'aptos';
import { TransactionPayload_EntryFunctionPayload } from 'aptos/src/generated';
import { argToString } from './serializer';

const GAP_UNIT = 100;
const MAX_GAS_AMOUNT = 50000;
export const MAX_GAS_PER_TX = GAP_UNIT * MAX_GAS_AMOUNT;

export const createProvider = ({
  nodeUrl,
  network,
}: {
  nodeUrl: string;
  network: Network;
}) => {
  const aptosConfig = new AptosConfig({ fullnode: nodeUrl, network });
  const aptos = new Aptos(aptosConfig);

  const client = new AptosClient(nodeUrl, { WITH_CREDENTIALS: false });

  const processData = (item: any) => item;
  const getResource: AptosProvider['getResource'] = async ({
    program,
    module,
    address,
    resourceType,
  }) => {
    const res = await client.getAccountResource(
      address,
      `${program}::${module}::${resourceType}`,
    );

    return processData(res.data) as Record<string, unknown>;
  };

  type GetGenericRes = AptosProvider['getGenericResources'];
  const getGenericResources: GetGenericRes = async ({
    module,
    address,
    genericResourceType,
  }) => {
    const res = await client.getAccountResources(address);
    // type: 0x1::AptosAccount::Coin
    return processData(
      res
        .filter(resource =>
          resource.type.includes(`${module}::${genericResourceType}`),
        )
        .map(v => ({ ...v.data, resourceType: v.type })),
    );
  };

  type GetResByType = AptosProvider['getGenericResourceByType'];
  const getResourceByType: GetResByType = async ({
    program,
    module,
    address,
    genericResourceType,
    typeArgs,
  }) => {
    const res = await client.getAccountResource(
      address,
      `${program}::${module}::${genericResourceType}<${typeArgs.join(
        ', ',
      )}>`,
    );
    return processData(res.data) as Record<string, unknown>;
  };

  const makePayload: AptosProvider['makePayload'] = ({
    program,
    module,
    args,
    typeArgs,
    functionName,
  }) => {
    const payload = {
      type: 'entry_function_payload',
      function: `${program}::${module}::${functionName}`,
      type_arguments: typeArgs,
      arguments: args.map(({ value, moveType }) =>
        argToString(value, moveType),
      ),
    };
    return payload;
  };

  const sendTx: AptosProvider['sendTx'] = async params => {
    const wallet = getWalletCtx();
    if (!wallet) {
      throw new Error('Please connect wallet.');
    }

    const payload = makePayload(params);

    // eslint-disable-next-line no-console
    console.log('[Build Payload]', payload);
    const sign = wallet.signAndSubmitTransaction;

    const res = await sign({
      data: {
        function: payload.function as `${string}::${string}::${string}`,
        typeArguments: payload.type_arguments,
        functionArguments: payload.arguments,
      },
      options: {
        maxGasAmount: MAX_GAS_AMOUNT,
      },
    });

    await client.waitForTransaction(res.hash);

    const tx: any = await client.getTransactionByHash(res.hash);

    if (tx.success) {
      return {
        success: true,
        txId: res.hash,
        payload: res.payload,
        rawTx: tx,
      };
    }

    return {
      success: false,
      txId: res.hash,
      payload: res.payload,
      message: tx.vm_status,
      rawTx: tx,
    };
  };

  const sendCustomTx = async (
    payloadJSON: TransactionPayload_EntryFunctionPayload,
  ): ReturnType<AptosProvider['sendTx']> => {
    const wallet = getWalletCtx();
    if (!wallet) {
      throw new Error('Please connect wallet.');
    }

    // eslint-disable-next-line no-console
    console.log('[Build Payload]', payloadJSON);
    const sign = wallet.signAndSubmitTransaction;

    const res = await sign({
      data: {
        function:
          payloadJSON.function as `${string}::${string}::${string}`,
        typeArguments: payloadJSON.type_arguments,
        functionArguments: payloadJSON.arguments,
      },
      options: {
        maxGasAmount: MAX_GAS_AMOUNT,
      },
    });

    await client.waitForTransaction(res.hash);

    const tx: any = await client.getTransactionByHash(res.hash);

    if (tx.success) {
      return {
        success: true,
        txId: res.hash,
        payload: res.payload,
        rawTx: tx,
      };
    }

    return {
      success: false,
      txId: res.hash,
      payload: res.payload,
      message: tx.vm_status,
      rawTx: tx,
    };
  };

  const simulateTx: AptosProvider['simulateTx'] = async params => {
    const wallet = getWalletCtx();
    if (!wallet) {
      throw new Error('Please connect wallet.');
    }

    const payload = makePayload(params);

    // eslint-disable-next-line no-console
    console.log('[Build Payload]', payload);

    try {
      const tx = await client
        .simulateTransaction(
          new TxnBuilderTypes.Ed25519PublicKey(
            new HexString(wallet.walletPublicKey!).toUint8Array(),
          ),
          await client.generateTransaction(wallet.getAddress()!, payload, {
            gas_unit_price: `${GAP_UNIT}`,
            max_gas_amount: `${MAX_GAS_AMOUNT}`,
          }),
        )
        .then(res => res.at(0));

      if (tx && tx.success) {
        return {
          success: true,
          payload: tx.payload,
          rawTx: tx,
        };
      }
      return {
        success: false,
        rawTx: tx,
        message: tx?.vm_status ?? 'unknown failure in simulation',
      };
    } catch (err: any) {
      return {
        success: false,
        rawTx: null,
        message: `Simulation failed${err.message}`,
      };
    }
  };

  const provider: AptosProvider = {
    getGenericResourceByType: getResourceByType,
    getResource,
    getGenericResources,
    sendTx,
    simulateTx,
    makePayload,
    getWalletAddress: () => {
      const address = getWalletCtx()?.getAddress();
      if (!address) {
        throw new Error('Please connect your wallet');
      }

      return address;
    },
    client,
  };

  return Object.assign(provider, { sendCustomTx, aptos });
};
