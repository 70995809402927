/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  ariesApi,
  useBalanceHub,
  useTokenInfoHub,
} from '@aries/aptos-defi/common';
import { getAriesSDK } from '@aries/aptos-defi/common/aries-sdk';
import { withSendTxNotify } from '@aries/aptos-defi/utils';
import { Asset, Big } from '@aries/defi-toolkit/types';
import { delay } from '@aries/shared/utils';
import { compact, isNil, sortBy, sumBy } from 'lodash';
import { useMemo } from 'react';
import useSWR from 'swr';
import { removeProfileNamePrefix } from '../utils/remove-profile-name-prefix';

export const useUnhealthyProfiles = () => {
  const { tokenMap } = useTokenInfoHub();
  const { balanceMap } = useBalanceHub();

  const { data = [], isValidating: loading } = useSWR(
    ['Unhealthy Profiles'],
    async () => {
      await delay(3000);
      return ariesApi.profile.currentUnhealthy.query();
    },
  );

  const unhealthyProfiles = useMemo(() => {
    const list = data.map(v => {
      return {
        id: `${v.userAddress}`,
        profile: removeProfileNamePrefix(v.profileName),
        riskPct: isNil(v.profile.riskFactor)
          ? 0
          : Number((v.profile.riskFactor * 100).toFixed(0)),
        depositedValueUSD: sumBy(
          Object.values(v.profile.deposits).map(v => v.collateral_value),
        ),
        borrowedValueUSD: sumBy(
          Object.values(v.profile.borrows).map(v => v.borrowed_value),
        ),
        depositedAssets: compact(
          Object.entries(v.profile.deposits).map(([coinAddress, val]) => {
            const { collateral_coins } = val;

            const asset = tokenMap[coinAddress];
            if (!asset) return null;
            return {
              asset,
              balance: asset.lamportsToBalance(
                Big(collateral_coins.toFixed(0)),
              ),
            };
          }),
        ),
        borrowedAssets: compact(
          Object.entries(v.profile.borrows).map(([coinAddress, val]) => {
            const { borrowed_coins } = val;
            const asset = tokenMap[coinAddress];
            if (!asset) return null;
            return {
              asset,
              balance: asset.lamportsToBalance(
                Big(borrowed_coins.toFixed(0)),
              ),
            };
          }),
        ),
        liquidate: async (from: Asset, to: Asset, amount: number) => {
          const fn = withSendTxNotify(() =>
            getAriesSDK()
              .controller.liquidateAndRedeem(
                {
                  liquidatee_addr: v.userAddress,
                  liquidatee_profile_name: removeProfileNamePrefix(
                    v.profileName,
                  ),
                  amount: from.toLamports(amount),
                },
                { RepayCoin: from.id, WithdrawCoin: to.id },
              )
              .execute(),
          );

          return fn();
        },
        predictReceive: (from: Asset, to: Asset, amount: number) => {
          const receiveLamports =
            v.profile.deposits[to.id]?.collateral_amount ?? 0;

          return to.lamportsToBalance(Big(receiveLamports));
        },
        getMaxInput: (from: Asset, to: Asset) => {
          const maxAmount = balanceMap[from.id]?.amount ?? Big(0);
          return from.lamportsToBalance(maxAmount);
        },
        canLiquidate: true,
      };
    });

    return sortBy(list, v => -v.riskPct);
  }, [data, tokenMap, balanceMap]);

  return { unhealthyProfiles, loading };
};
